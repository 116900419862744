import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Schedule } from 'src/app/features/reports/models/schedule.model';
import { GenericCache } from 'src/app/shared/utils/cache';

@Injectable({
	providedIn: 'root',
})
export class ReportsScheduleService {
	constructor(private httpClient: HttpClient) {}

	refreshCache = false;
	private cachedSchedules: GenericCache<Schedule> = new GenericCache<Schedule>('scheduleId');

	public clearCache() {
		this.cachedSchedules.clearCache();
	}

	public getSchedules(refreshCache?: boolean): Observable<HttpResponse<Object>> | Observable<Object> {
		if (this.refreshCache || refreshCache || this.cachedSchedules.isEmpty()) {
			const url = environment.trovataAPI('workspace') + '/export/schedules';
			return this.httpClient
				.get<Schedule[]>(url, {
					observe: 'response',
				})
				.pipe(
					map(resp => {
						this.cachedSchedules.updateItems(<Schedule[]>resp.body);
						this.refreshCache = false;
						return resp;
					}),
					catchError(err => throwError(err))
				);
		} else {
			const resp = { body: this.cachedSchedules.getItems() };
			return of(resp);
		}
	}

	public getSchedulesByResourceId(resourceId: string, refreshCache?: boolean): Observable<Schedule[]> {
		if (this.refreshCache || refreshCache || this.cachedSchedules.isEmpty()) {
			const schedules = (this.getSchedules() as any).pipe(
				map(result => (<Schedule[]>(result as any).body).filter(sched => sched?.distribution?.resourceIdentifer === resourceId))
			);
			return schedules;
		} else {
			const schedules = this.cachedSchedules.getItems().filter(sched => sched?.distribution?.resourceIdentifer === resourceId);
			return of(schedules);
		}
	}

	public postSchedule(schedule: Schedule): Observable<HttpResponse<Object>> {
		const url = environment.trovataAPI('workspace') + '/export/schedule';
		return this.httpClient
			.post(url, schedule, {
				observe: 'response',
			})
			.pipe(
				map(resp => {
					schedule.scheduleId = resp.body['scheduleId'];
					this.cachedSchedules.updateItem(schedule);
					return resp;
				}),
				catchError(err => throwError(err))
			);
	}

	public deleteSchedule(scheduleId: string): Observable<HttpResponse<Object>> {
		const url = environment.trovataAPI('workspace') + '/export/schedule?scheduleId=' + scheduleId;
		return this.httpClient
			.delete(url, {
				observe: 'response',
			})
			.pipe(
				map(resp => {
					this.cachedSchedules.removeItem(scheduleId);
					return resp;
				}),
				catchError(err => throwError(err))
			);
	}

	public putSchedule(schedule: Schedule): Observable<HttpResponse<Object>> {
		const url = environment.trovataAPI('workspace') + '/export/schedule?scheduleId=' + schedule.scheduleId;
		return this.httpClient
			.put(url, schedule, {
				observe: 'response',
			})
			.pipe(
				map(resp => {
					this.cachedSchedules.updateItem(schedule);
					return resp;
				}),
				catchError(err => throwError(err))
			);
	}
}
