import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import {
	AnalysisBalanceProperty,
	AnalysisBalanceValue,
	AnalysisDataRoot,
	AnalysisTransactionValue,
	AnalysisTrendMetric,
	AnalysisType,
	BalanceAnalysisChartHeadline,
} from '../../reports/models/analysis.model';
import { ElementType, ReportType, ReportV4 } from '../../reports/models/report.model';
import { ChartType } from '@trovata/app/shared/models/highcharts.model';
import { DateTime } from 'luxon';
import { Cadence } from '@trovata/app/shared/models/cadence.model';
import { TQLPayload } from '@trovata/app/shared/models/tql.model';
import { RollingType } from '@trovata/app/shared/models/date-range-picker.model';

export const getDynamicCadence = (startDate: string, endDate: string): Cadence => {
	const start: DateTime = DateTime.fromISO(startDate);
	const end: DateTime = DateTime.fromISO(endDate);
	const diffInDays: number = end.diff(start, 'days').days;

	if (diffInDays <= 30) {
		return Cadence.daily;
	} else if (diffInDays <= 210) {
		return Cadence.weekly;
	} else if (diffInDays <= 900) {
		return Cadence.monthly;
	} else {
		return Cadence.quarterly;
	}
};

export const getCadenceDisplayValues = (cadence: Cadence): string => {
	switch (cadence) {
		case Cadence.daily:
			return 'Daily';
		case Cadence.weekly:
			return 'Weekly';
		case Cadence.monthly:
			return 'Monthly';
		case Cadence.quarterly:
			return 'Quarterly';
		default:
			return '';
	}
};

export const getHomeBalanceReport = (
	startDate: string,
	endDate: string,
	tql?: TQLPayload,
	analysisData?: AnalysisDataRoot<AnalysisBalanceValue>
): ReportV4 => ({
	reportType: ReportType.report,
	name: 'Balances By Entity',
	reportId: 'home-balances-report',
	elements: [
		{
			type: AnalysisType.balances,
			parameters: {
				analysisType: AnalysisType.balances,
				cadence: getDynamicCadence(startDate, endDate),
				balanceProperty: AnalysisBalanceProperty.compositeBalanceConverted,
				groupBy: [GroupByKey.entityId],
				tql: tql,
			},
			preferences: {
				visualType: ElementType.chart,
				elementOrder: 0,
				balanceProperty: '',
				displaySettings: {
					chartType: ChartType.stackedbar,
					netToggle: false,
					chartToggle: true,
					tableToggle: false,
					userOrdered: {},
					trendMetrics: true,
					trueRounding: false,
					headlineValue: BalanceAnalysisChartHeadline.currentBalance,
					primaryMetric: AnalysisTrendMetric.totalChange,
					headlineToggle: true,
					trueRoundingOption: null,
				},
				calendarSettings: {
					lastModifiedDate: endDate,
					rollingType: null,
					showWeekends: true,
					customOptionCadence: null,
					customOptionPeriods: null,
					toDateOption: null,
					endDate: endDate,
					startDate: startDate,
				},
			},
		},
	],
	reportData: [{ elementId: 'homeBalanceElement', data: analysisData }],
});

export const getHomeInflowsReport = (
	startDate: string,
	endDate: string,
	tql?: TQLPayload,
	analysisData?: AnalysisDataRoot<AnalysisTransactionValue>
): ReportV4 => {
	const dynamicCadence: Cadence = getDynamicCadence(startDate, endDate);
	return {
		reportType: ReportType.report,
		name: `Average ${getCadenceDisplayValues(dynamicCadence)} Inflows`,
		reportId: 'home-inflows-report',
		elements: [
			{
				type: AnalysisType.transactions,
				parameters: {
					analysisType: AnalysisType.transactions,
					cadence: dynamicCadence,
					balanceProperty: AnalysisBalanceProperty.compositeBalanceConverted,
					tql: tql,
				},
				preferences: {
					visualType: ElementType.chart,
					elementOrder: 0,
					balanceProperty: '',
					displaySettings: {
						chartType: ChartType.column,
						netToggle: false,
						chartToggle: true,
						tableToggle: false,
						userOrdered: {},
						trendMetrics: true,
						trueRounding: false,
						headlineValue: null,
						primaryMetric: AnalysisTrendMetric.average,
						headlineToggle: true,
						trueRoundingOption: null,
					},
					calendarSettings: {
						lastModifiedDate: endDate,
						rollingType: RollingType.RollingBoth,
						showWeekends: true,
						customOptionCadence: null,
						customOptionPeriods: null,
						toDateOption: null,
						endDate: endDate,
						startDate: startDate,
					},
				},
			},
		],
		reportData: [{ elementId: 'homeInflowsElement', data: analysisData }],
	};
};

export const getHomeOutflowsReport = (
	startDate: string,
	endDate: string,
	tql?: TQLPayload,
	analysisData?: AnalysisDataRoot<AnalysisTransactionValue>
): ReportV4 => {
	const dynamicCadence: Cadence = getDynamicCadence(startDate, endDate);
	return {
		reportType: ReportType.report,
		name: `Average ${getCadenceDisplayValues(dynamicCadence)} Outflows`,
		reportId: 'home-outflows-report',
		elements: [
			{
				type: AnalysisType.transactions,
				parameters: {
					analysisType: AnalysisType.transactions,
					cadence: dynamicCadence,
					balanceProperty: AnalysisBalanceProperty.compositeBalanceConverted,
					tql: tql,
				},
				preferences: {
					visualType: ElementType.chart,
					elementOrder: 0,
					balanceProperty: '',
					displaySettings: {
						chartType: ChartType.column,
						netToggle: false,
						chartToggle: true,
						tableToggle: false,
						userOrdered: {},
						trendMetrics: true,
						trueRounding: false,
						headlineValue: null,
						primaryMetric: AnalysisTrendMetric.average,
						headlineToggle: true,
						trueRoundingOption: null,
					},
					calendarSettings: {
						lastModifiedDate: endDate,
						rollingType: RollingType.RollingBoth,
						showWeekends: true,
						customOptionCadence: null,
						customOptionPeriods: null,
						toDateOption: null,
						endDate: endDate,
						startDate: startDate,
					},
				},
			},
		],
		reportData: [{ elementId: 'homeOutflowsElement', data: analysisData }],
	};
};
