import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { AllPreferences, CurrencyPreferences, PreferencesV2Key, PreferencesV2OwnerFlag, PreferenceV2 } from 'src/app/shared/models/preferences.model';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { PreferencesService } from 'src/app/shared/services/preferences.service';
import {
	ClearPreferencesState,
	GetAllPreferences,
	GetCurrencyPreferences,
	InitPreferencesState,
	OverridePreferenceManually,
	PostCurrencyPreferences,
	ResetPreferencesState,
	UpdatePreferencesByKey,
} from '../../actions/preferences.actions';
import { HttpResponse } from '@angular/common/http';
import { PreferencesV2Service } from '../../../services/preferencesV2.service';
import { UpdateStrategy } from '../../../utils/update-strategy';
import { ReportsPagePreferences } from 'src/app/features/reports/models/report.model';
import { ForecastsPagePreferences } from '@trovata/app/features/forecasts/models/forecast-forecast.model';
import { environment } from '@trovata/environments/environment';

export class PreferencesStateModel {
	preferences: AllPreferences;
	defaultCurrencyCode: string;
	instanceCurrencyCode: string;
	userCurrencyCode: string;
}

@State<PreferencesStateModel>({
	name: 'preferences',
	defaults: {
		preferences: null,
		defaultCurrencyCode: null,
		instanceCurrencyCode: null,
		userCurrencyCode: null,
	},
})
@Injectable()
export class PreferencesState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	@Selector()
	static preferences(state: PreferencesStateModel) {
		return state.preferences;
	}

	@Selector()
	static carouselPreferences(state: PreferencesStateModel) {
		return state.preferences.balancesCarousel?.snacks;
	}

	@Selector()
	static defaultCurrencyCode(state: PreferencesStateModel) {
		return state.defaultCurrencyCode;
	}
	@Selector()
	static userCurrencyCode(state: PreferencesStateModel) {
		return state.defaultCurrencyCode ? state.userCurrencyCode || '' : null;
	}
	@Selector()
	static instanceCurrencyCode(state: PreferencesStateModel) {
		return state.defaultCurrencyCode ? state.instanceCurrencyCode || environment.defaultCurrency : null;
	}

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private preferencesService: PreferencesService,
		private preferencesV2Service: PreferencesV2Service
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitPreferencesState)
	async initPreferencesState(context: StateContext<PreferencesStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const preferencesStateIsCached: boolean = this.preferencesStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (preferencesStateIsCached && appReady) {
						const state: PreferencesStateModel = deserializedState.preferences;
						context.patchState(state);
					} else if (!preferencesStateIsCached && appReady) {
						context.dispatch(new GetAllPreferences());
						context.dispatch(new GetCurrencyPreferences());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetAllPreferences)
	getAllPreferences(context: StateContext<PreferencesStateModel>): void {
		// TODO: To enhance bandwidth efficiency, add some sort of check to compare state.preferences to any changed data on the back end to prevent large, unnecessary api request/responses
		combineLatest([
			this.preferencesService.getAllPreferences('global'),
			this.preferencesService.getAllPreferences('global/reportsPagePreferences'),
			this.preferencesService.getAllPreferences('global/forecastsPagePreferences'),
		]).subscribe({
			next: (responses: [HttpResponse<AllPreferences>, HttpResponse<ReportsPagePreferences>, HttpResponse<ForecastsPagePreferences>]) => {
				const preferences: AllPreferences = responses[0].body;
				const state: PreferencesStateModel = context.getState();
				state.preferences = preferences;
				state.preferences.reportsPagePreferences = responses[1].body;
				state.preferences.forecastsPagePreferences = responses[2].body;
				context.patchState(state);
			},
			error: error => throwError(() => error),
		});
	}

	@Action(GetCurrencyPreferences)
	getCurrencyPreferences(context: StateContext<PreferencesStateModel>): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			combineLatest([
				this.preferencesV2Service.getV2PreferencesByKey<CurrencyPreferences>(PreferencesV2OwnerFlag.instance, PreferencesV2Key.currency),
				this.preferencesV2Service.getV2PreferencesByKey<CurrencyPreferences>(PreferencesV2OwnerFlag.user, PreferencesV2Key.currency),
			]).subscribe({
				next: (responses: [CurrencyPreferences, CurrencyPreferences]) => {
					const state: PreferencesStateModel = context.getState();
					const instanceCurrPreference: CurrencyPreferences = responses[0];
					const userCurrPreference: CurrencyPreferences = responses[1];
					if (instanceCurrPreference) {
						state.instanceCurrencyCode = instanceCurrPreference.currency;
					}
					if (userCurrPreference) {
						state.userCurrencyCode = userCurrPreference.currency;
					}
					this.setDefaultCurrencyCode(state, context);
					resolve();
				},
				error: err => {
					reject(err);
				},
			});
		});
	}

	@Action(PostCurrencyPreferences)
	postCurrencyPreferences(context: StateContext<PreferencesStateModel>, action: PostCurrencyPreferences): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const requests: Observable<HttpResponse<object>>[] = [];
			const state: PreferencesStateModel = context.getState();
			if (action.instanceCurrency && action.instanceCurrency !== state.instanceCurrencyCode) {
				if (state.instanceCurrencyCode) {
					requests.push(
						this.preferencesV2Service.putV2Preference(PreferencesV2OwnerFlag.instance, { currency: action.instanceCurrency }, PreferencesV2Key.currency)
					);
				} else {
					requests.push(
						this.preferencesV2Service.postV2Preference(PreferencesV2OwnerFlag.instance, { currency: action.instanceCurrency }, PreferencesV2Key.currency)
					);
				}
				state.instanceCurrencyCode = action.instanceCurrency;
			}
			if (action.userCurrency && action.userCurrency !== state.userCurrencyCode) {
				if (state.userCurrencyCode) {
					requests.push(this.preferencesV2Service.putV2Preference(PreferencesV2OwnerFlag.user, { currency: action.userCurrency }, PreferencesV2Key.currency));
				} else {
					requests.push(this.preferencesV2Service.postV2Preference(PreferencesV2OwnerFlag.user, { currency: action.userCurrency }, PreferencesV2Key.currency));
				}
				state.userCurrencyCode = action.userCurrency;
			}
			if (requests.length > 0) {
				combineLatest(requests).subscribe(
					() => {
						this.setDefaultCurrencyCode(state, context);
						resolve();
					},
					err => {
						reject();
					}
				);
			}
		});
	}

	private setDefaultCurrencyCode(state: PreferencesStateModel, context: StateContext<PreferencesStateModel>) {
		state.defaultCurrencyCode = state.userCurrencyCode || state.instanceCurrencyCode || environment.defaultCurrency;
		context.patchState(state);
	}

	@Action(UpdatePreferencesByKey)
	updatePreferencesByKey(context: StateContext<PreferencesStateModel>, action: UpdatePreferencesByKey): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				if (action.updateStrategy === UpdateStrategy.optimistic) {
					this.updatePreferenceByKey(context, action.key, action.preferences);
					firstValueFrom(this.preferencesService.postPreference('global', action.key, action.preferences));
				} else if (action.updateStrategy === UpdateStrategy.pessimistic) {
					await firstValueFrom(this.preferencesService.postPreference('global', action.key, action.preferences));
					this.updatePreferenceByKey(context, action.key, action.preferences);
				}
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	// Some prefences must be sent to the UI in different forms than they come back, this allows updates to the state to fix that without having to refetch all prefs
	@Action(OverridePreferenceManually)
	overridePreferenceManually(context: StateContext<PreferencesStateModel>, action: UpdatePreferencesByKey): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				this.updatePreferenceByKey(context, action.key, action.preferences);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(ResetPreferencesState)
	resetPreferencesState(context: StateContext<PreferencesStateModel>) {
		context.dispatch(new ClearPreferencesState());
		context.dispatch(new InitPreferencesState());
	}

	@Action(ClearPreferencesState)
	clearPreferencesState(context: StateContext<PreferencesStateModel>) {
		this.appReadySub.unsubscribe();
		const state: PreferencesStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private updatePreferenceByKey(context: StateContext<PreferencesStateModel>, key: string, preferences: any) {
		const state = context.getState();
		state.preferences[key] = preferences;
		context.patchState(state);
	}

	private preferencesStateIsCached(deserializedState: TrovataAppState | undefined): boolean {
		if (deserializedState && deserializedState.preferences) {
			// fix for weird headless cypress test bug
			const deserializedPreferencesState: PreferencesStateModel = deserializedState.preferences;
			if (deserializedPreferencesState.preferences && deserializedPreferencesState.defaultCurrencyCode) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
