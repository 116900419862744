import { CashPositionPagePreferences } from '../../features/cash-position/models/cash-position.model';
import { BatchPaymentsListColumns } from '../../features/payments/models/paginated-batch-payments-table-view.model';
import { AllForecastV3Preferences, AllStreamPreferences, ForecastsPagePreferences } from '../../features/forecasts/models/forecast-forecast.model';
import { PaymentsListColumns } from '../../features/payments/models/paginated-payments-table-view.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { ReportsPagePreferences } from '../../features/reports/models/report.model';
import { AcctGroupColumns, AcctGroupTypeOption } from './balances-grouping-view.model';
import { SnackType } from './snacks.model';
import { TagChartPreferences } from '../../features/transactions/models/tag.model';
import { ReconciliationReportPreferences } from '../../features/reports/models/reconciliation.model';
import { TransactionsPagePreferences } from '../../features/transactions/models/transaction.model';
import { StatementsPagePreferences } from 'src/app/features/statements/models/statements.models';
import { ItemViewedEvent } from '@trovata/app/features/home/models/recently-viewed';
import { GroupByKey } from '../utils/key-translator';

/**
 * TODO: Set proper data types for any
 */
export interface AllPreferences {
	accountDetailsChartData: any;
	balancesAccountGrouping: AccountGroupingPreferences;
	balanceDisplayProperties: any;
	balancesCarousel: BalancesCarouselPreferences;
	cashPositionSnacks: any;
	cashPositionPagePreferences: CashPositionPagePreferences;
	dashboardTabs: any;
	forecastV3Preferences: AllForecastV3Preferences;
	forecastsPagePreferences: ForecastsPagePreferences;
	reconReportPreferences: { [reconId: string]: ReconciliationReportPreferences };
	reportsPagePreferences: ReportsPagePreferences;
	transactionsPagePreferences: TransactionsPagePreferences;
	reportCenterTabData: any;
	reportV3GroupingData: any;
	streamPreferences: AllStreamPreferences;
	tagChartData: TagChartPreferences;
	transactionGridColumnConfigs: any;
	paymentsListColumns: PaymentsListColumns[];
	paymentsBatchListColumns: BatchPaymentsListColumns[];
	statementsPreferences: StatementsPagePreferences;

	/**
	 * @deprecated
	 * recentPageVisits: ItemViewedEvent[];
	 * homePreferences: HomePreferences;
	 * vectorGroupingData: any;
	 * streamDataGroupingData: any;
	 * streamExpansionData: any;
	 * streamGroupingData: any;
	 * reportGroupingData: any;
	 * reconDailyDisplayProperties: string[];
	 * reconDateRangeDisplayProperties: string[];
	 * dashboardTabs2: any;
	 * dataDisplay: any;
	 * dismissClassicReportsBanner: boolean;
	 * dismissClassicForecastsBanner: boolean;
	 * forecastGroupingData: any;
	 * accountPositionGridLayout: any;
	 * accountPositionGridLayoutTest: any;
	 */
}

export interface AccountGroupingPreferences {
	sortCol: AcctGroupColumns;
	sortAsc: boolean;
	orderedIncludedCol: AcctGroupColumns[];
	columnVisibility?: ColumnVisibility;
	showFullAcctNumbers: boolean;
	groupBy: AcctGroupTypeOption;
}

export interface ColumnVisibility {
	[key: string]: boolean;
}

export interface SnackPreference {
	id: string;
	type: SnackType;
	preferences?: SnackPreferenceSettings;
	deleted?: boolean;
}

export interface SnackPreferenceSettings {
	flipped?: boolean;
	cadence?: Cadence;
	periods?: number;
	chartType?: string;
	netToggle?: boolean;
	summary?: boolean;
	chart?: boolean;
	index?: number;
}

export interface BalancesCarouselPreferences {
	snacks: SnackPreference[];
}

// V2 Preferences
export interface HomePreferences {
	accountIds: string[];
	recentPageVisits: ItemViewedEvent[];
	defaultGroupBy: GroupByKey;
}

export interface CurrencyPreferences {
	currency: string;
}

export interface PreferenceV2<T> {
	id: string;
	ownerFlag: string;
	preference: T;
	preferenceKey: string;
}

export enum PreferencesV2OwnerFlag {
	instance = 'instance',
	user = 'user',
}

export enum PreferencesV2Key {
	currency = 'currency',
	home = 'home',
}

export interface PreferencesV2Body {
	preference: unknown;
	preferenceKey: string;
	ownerFlag: PreferencesV2OwnerFlag;
}
