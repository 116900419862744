import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { PreferencesV2Body, PreferencesV2Key, PreferencesV2OwnerFlag, PreferenceV2 } from '../models/preferences.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PreferencesV2Service {
	constructor(private httpClient: HttpClient) {}

	/**
	 * @description Returns all the preferences associated with a resource.
	 * @param {boolean?} refreshCache If you want to force a cache refresh on this request.
	 * @return {Observable<HttpResponse<any[]>>}  The standard return object we have been using for requests.
	 */
	public getV2PreferencesByKey<T>(ownerFlag: string, preferenceKey: PreferencesV2Key): Observable<T> {
		const url: string = environment.trovataAPI('workspace') + '/data/preferences';

		let parameters: HttpParams = new HttpParams();
		if (ownerFlag) {
			parameters = parameters.append('ownerFlag', ownerFlag);
		}
		if (ownerFlag) {
			parameters = parameters.append('preferenceKey', preferenceKey);
		}

		return this.httpClient
			.get<PreferenceV2<T>[]>(url, {
				params: parameters,
				observe: 'response',
			})
			.pipe(
				map(resp => {
					const preferenceV2: PreferenceV2<T> = resp.body.find((preference: PreferenceV2<T>) => preference.preferenceKey === preferenceKey);
					return preferenceV2?.preference;
				})
			);
	}

	postV2Preference(ownerFlag: PreferencesV2OwnerFlag, preference: unknown, preferenceKey: PreferencesV2Key): Observable<HttpResponse<Object>> {
		const body: PreferencesV2Body = {
			preference: preference,
			preferenceKey: preferenceKey,
			ownerFlag: ownerFlag,
		};

		const url: string = environment.trovataAPI('workspace') + '/data/preferences';
		return this.httpClient
			.post(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	putV2Preference(ownerFlag: PreferencesV2OwnerFlag, preference: unknown, preferenceKey: PreferencesV2Key): Observable<HttpResponse<Object>> {
		const body: PreferencesV2Body = {
			preference: preference,
			preferenceKey: preferenceKey,
			ownerFlag: ownerFlag,
		};
		const url: string = environment.trovataAPI('workspace') + '/data/preferences';
		return this.httpClient
			.put(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}
}
