import { Injectable, signal, WritableSignal } from '@angular/core';
import { Observable, throwError, map, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
	AnalysisDataSettings,
	AnalysisType,
	AnalysisGetRequestParams,
	AnalysisTransactionValue,
	AnalysisDataRoot,
	AnalysisBalanceValue,
	AnalysisDisplaySettings,
	AnalysisPDFRequestParams,
	AnalysisPDFResponse,
} from '../models/analysis.model';
import { catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { FilterKey, GroupByKeyLegacy } from '@trovata/app/shared/utils/key-translator';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { defaultCurrency } from '@trovata/app/shared/models/currency.model';

@Injectable({
	providedIn: 'root',
})
export class AnalysisService {
	constructor(private httpClient: HttpClient) {}

	getTransactionsAnalysis({
		cadence,
		groupBy,
		excludeWeekends,
		currencyOverride,
		tqlJSONExpression,
		startDate,
		endDate,
		customMetric,
		tagDelta,
	}: AnalysisGetRequestParams): Observable<AnalysisDataRoot<AnalysisTransactionValue>> {
		const body: AnalysisGetRequestParams = {
			cadence: cadence,
			startDate: startDate,
			endDate: endDate,
			customMetric,
		};
		body['cadence'] = cadence;
		if (excludeWeekends) {
			body['excludeWeekends'] = true;
		}
		if (groupBy) {
			body['groupBy'] = groupBy;
		}
		if (currencyOverride && currencyOverride !== defaultCurrency.code) {
			body['currencyOverride'] = currencyOverride;
		}
		if (tqlJSONExpression) {
			body['tql'] = {
				type: 'AST',
				expression: tqlJSONExpression,
			};
		}
		if (tagDelta) {
			body['tagDelta'] = tagDelta;
		}

		const url: string = `${environment.trovataAPI('workspace')}` + '/data/v5/transactions/analysis';
		return this.httpClient
			.post<AnalysisDataRoot<AnalysisTransactionValue>>(url, body, {
				observe: 'response',
			})
			.pipe(
				map(resp => resp.body),
				catchError(err => throwError(() => err))
			);
	}

	getBalanceAnalysis({
		cadence,
		groupBy,
		currencyOverride,
		balanceProperty,
		excludeWeekends,
		tqlJSONExpression,
		startDate,
		endDate,
		customMetric,
	}: AnalysisGetRequestParams): Observable<AnalysisDataRoot<AnalysisBalanceValue>> {
		const body: AnalysisGetRequestParams = {
			cadence: cadence,
			startDate: startDate,
			endDate: endDate,
			customMetric,
		};
		if (excludeWeekends && cadence === 'daily') {
			body['excludeWeekends'] = true;
		}
		if (groupBy) {
			body['groupBy'] = groupBy;
		}
		if (currencyOverride && currencyOverride !== defaultCurrency.code) {
			body['currencyOverride'] = currencyOverride;
		}

		if (balanceProperty) {
			body['balanceProperty'] = balanceProperty;
		}

		if (tqlJSONExpression) {
			body['tql'] = {
				type: 'AST',
				expression: tqlJSONExpression,
			};
		}
		const url: string = `${environment.trovataAPI('workspace')}` + '/data/v4/accounts/balances/historical/analysis';
		return this.httpClient
			.post<AnalysisDataRoot<AnalysisBalanceValue>>(url, body, {
				observe: 'response',
			})
			.pipe(
				map(resp => resp.body),
				catchError(err => throwError(err))
			);
	}

	getAnalysisPDF(
		tqlJSON: Object,
		displaySettings: AnalysisDisplaySettings,
		dataSettings: AnalysisDataSettings,
		calendarSettings: CalendarSettings
	): Observable<AnalysisPDFResponse> {
		const url: string = environment.trovataAPI('workspace') + '/export/analysis/pdf';
		const requestBody: AnalysisPDFRequestParams = {
			displaySettings: displaySettings,
			tqlJSONExpression: tqlJSON,
			dataSettings: dataSettings,
			calendarSettings: calendarSettings,
		};
		return this.httpClient.post<AnalysisPDFResponse>(url, requestBody, { observe: 'response' }).pipe(
			map(resp => resp.body),
			catchError(err => throwError(() => err))
		);
	}

	getFilterKeyFromGroupByKey(groupByKey: GroupByKeyLegacy): FilterKey {
		switch (groupByKey) {
			case GroupByKeyLegacy.account:
				return FilterKey.accountId;
			case GroupByKeyLegacy.institution:
				return FilterKey.institutionId;
			case GroupByKeyLegacy.currency:
				return FilterKey.currency;
			case GroupByKeyLegacy.divisionGroupingId:
				return FilterKey.division;
			case GroupByKeyLegacy.entityGroupingId:
				return FilterKey.entity;
			case GroupByKeyLegacy.regionGroupingId:
				return FilterKey.region;
			case GroupByKeyLegacy.tag:
				return FilterKey.tag;
		}
	}

	getGroupByKeyFromFilerKey(filterKey: FilterKey): GroupByKeyLegacy {
		switch (filterKey) {
			case FilterKey.accountId:
				return GroupByKeyLegacy.account;
			case FilterKey.institutionId:
				return GroupByKeyLegacy.institution;
			case FilterKey.currency:
				return GroupByKeyLegacy.currency;
			case FilterKey.division:
				return GroupByKeyLegacy.divisionGroupingId;
			case FilterKey.region:
				return GroupByKeyLegacy.regionGroupingId;
			case FilterKey.entity:
				return GroupByKeyLegacy.entityGroupingId;
			case FilterKey.tag:
				return GroupByKeyLegacy.tag;
		}
	}

	getGroupByKeyFromLegacyKey(legacyFilterKey: GroupByKeyLegacy): GroupByKeyLegacy {
		switch (legacyFilterKey) {
			case GroupByKeyLegacy.account:
				return GroupByKeyLegacy.account;
			case GroupByKeyLegacy.institution:
				return GroupByKeyLegacy.institution;
			case GroupByKeyLegacy.currency:
				return GroupByKeyLegacy.currency;
			case GroupByKeyLegacy.tag:
				return GroupByKeyLegacy.tag;
		}
	}
}

export interface AnalysisRequestData {
	tqlPayload: Object;
	dataSettings: AnalysisDataSettings;
	calendarSettings: CalendarSettings;
}

export class AnalysisRequestHandler {
	private analysisDataSubject$: Subject<AnalysisDataRoot<AnalysisBalanceValue> | AnalysisDataRoot<AnalysisTransactionValue>>;
	private destroyed$: Subject<void>;
	analysisData$: Observable<any>;
	requestData$: BehaviorSubject<AnalysisRequestData>;
	apiRequestInFlight: WritableSignal<boolean>;
	hasAPIError: boolean;

	constructor(
		private analysisService: AnalysisService,
		defaultData?: AnalysisDataRoot<AnalysisBalanceValue> | AnalysisDataRoot<AnalysisTransactionValue>
	) {
		this.destroyed$ = new Subject<void>();
		this.initDataObservable();
		this.initRequestDebouncer();
		this.apiRequestInFlight = signal(false);
		if (defaultData) {
			this.analysisDataSubject$.next(defaultData);
		}
	}

	private initDataObservable(): void {
		this.analysisDataSubject$ = new Subject();
		this.requestData$ = new BehaviorSubject(null);
		this.analysisData$ = this.analysisDataSubject$ as Observable<AnalysisDataRoot<AnalysisBalanceValue> | AnalysisDataRoot<AnalysisTransactionValue>>;
	}

	private initRequestDebouncer(): void {
		this.requestData$
			.pipe(
				takeUntil(this.destroyed$),
				debounceTime(10),
				switchMap((requestData: AnalysisRequestData) => {
					if (requestData) {
						this.apiRequestInFlight.set(true);
						this.hasAPIError = false;
						if (requestData.dataSettings.analysisType === AnalysisType.transactions) {
							return this.analysisService
								.getTransactionsAnalysis({
									cadence: requestData.dataSettings.cadence,
									groupBy: requestData.dataSettings.groupBy,
									currencyOverride: requestData.dataSettings.currencyOverride,
									excludeWeekends: !requestData.calendarSettings.showWeekends,
									tqlJSONExpression: requestData.tqlPayload,
									startDate: requestData.calendarSettings.startDate,
									endDate: requestData.calendarSettings.endDate,
									customMetric: requestData.dataSettings.customMetric,
									tagDelta: requestData.dataSettings.tagDelta,
								})
								.pipe(catchError(err => this.loadError(err)));
						} else if (requestData.dataSettings.analysisType === AnalysisType.balances) {
							return this.analysisService
								.getBalanceAnalysis({
									cadence: requestData.dataSettings.cadence,
									groupBy: requestData.dataSettings.groupBy,
									currencyOverride: requestData.dataSettings.currencyOverride,
									balanceProperty: requestData.dataSettings.balanceProperty,
									excludeWeekends: !requestData.calendarSettings.showWeekends,
									tqlJSONExpression: requestData.tqlPayload,
									startDate: requestData.calendarSettings.startDate,
									endDate: requestData.calendarSettings.endDate,
									customMetric: requestData.dataSettings.customMetric,
								})
								.pipe(catchError(err => this.loadError(err)));
						}
					}
					return EMPTY;
				})
			)
			.subscribe({
				next: resp => {
					this.analysisDataSubject$.next(resp);
					this.apiRequestInFlight.set(false);
				},
				error: () => this.loadError(),
			});
	}

	loadError(err?) {
		this.hasAPIError = true;
		return EMPTY;
	}

	requestAnalysisData(tqlPayload: Object, dataSettings: AnalysisDataSettings, calendarSettings: CalendarSettings): void {
		this.requestData$.next({ dataSettings, tqlPayload, calendarSettings });
	}

	cleanup(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
