import { Injectable } from '@angular/core';
import { AccountSummary, IHomeAccountSummaryRequest } from '../models/home.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@trovata/environments/environment';
import { Cadence } from '@trovata/app/shared/models/cadence.model';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';

@Injectable({
	providedIn: 'root',
})
export class AccountSummaryService {
	constructor(private httpClient: HttpClient) {}

	getAccountSummary(startDate: string, endDate: string, cadence: Cadence, groupBy: GroupByKey[], tql?: object): Observable<AccountSummary> {
		const body: IHomeAccountSummaryRequest = { startDate, endDate, tql, cadence, groupBy };
		const url: string = `${environment.trovataAPI('workspace')}` + '/data/v5/accounts/summary';
		return this.httpClient
			.post<AccountSummary>(url, body, {
				observe: 'response',
			})
			.pipe(
				map(resp => resp.body),
				catchError(err => throwError(err))
			);
	}
}
