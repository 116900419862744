import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { Observable, of } from 'rxjs';
import {
	CreateCustomerUserBody,
	CurrentUser,
	Customer,
	CustomerUser,
	EditCurrentUserBody,
	EditCurrentUserResponse,
	EditCustomerBody,
	EditCustomerUserBody,
	GetCustomerUsersResponse,
	SSOCompanyNameResponse,
	SSOConnection,
} from '../models/identity.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class IdentityService {
	constructor(
		private httpService: HttpService,
		private httpClient: HttpClient
	) {}

	getCustomer(): Observable<Customer> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/customer`);
	}

	getCurrentUser(): Observable<CurrentUser> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/user`);
	}

	getCustomerUsers(): Observable<GetCustomerUsersResponse> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/customer/users`);
	}

	editCustomer(body: EditCustomerBody): Observable<Customer> {
		return this.httpService.patch(`${environment.trovataAPI('workspace') + '/identity/customer'}`, body);
	}

	editCurrentUser(body: EditCurrentUserBody): Observable<EditCurrentUserResponse> {
		return this.httpService.patch(`${environment.trovataAPI('workspace')}/identity/user`, body);
	}

	editUserForCustomer(userId: string, body: EditCustomerUserBody): Observable<CustomerUser> {
		return this.httpService.patch(`${environment.trovataAPI('workspace')}/v2/identity/customer/users/${userId}`, body);
	}

	sendResetPasswordForCustomerUser(userId: string): Observable<void> {
		return this.httpService.post(`${environment.trovataAPI('workspace')}/admin/identity/customer/users/password/reset/${userId}`, undefined);
	}

	addNewUserForCustomer(body: CreateCustomerUserBody): Observable<any> {
		// TODO type when api works
		return this.httpService.post(`${environment.trovataAPI('workspace')}/v2/identity/customer/users`, body);
	}

	deleteUserFromCustomer(userId: string): Observable<null> {
		return this.httpService.delete(`${environment.trovataAPI('workspace')}/identity/customer/users/${userId}`);
	}

	deleteCustomer(): Observable<null> {
		return this.httpService.delete(`${environment.trovataAPI('workspace')}/identity/customer`);
	}

	// SSO endpoints
	getSSOConnection(): Observable<SSOConnection> {
		return this.httpClient.get<SSOConnection>(`${environment.trovataAPI('workspace')}/admin/identity/customer/sso/connection`);
	}

	// SSO endpoints
	getSSOCompanyName(): Observable<SSOCompanyNameResponse> {
		return this.httpClient.get<SSOCompanyNameResponse>(`${environment.trovataAPI('workspace')}/admin/identity/customer/sso/connection/name`);
	}

	createSSOConnection(connection: SSOConnection): Observable<SSOConnection> {
		return this.httpClient.post<SSOConnection>(`${environment.trovataAPI('workspace')}/admin/identity/customer/sso/connection`, connection);
	}

	updateSSOConnection(connection: SSOConnection): Observable<SSOConnection> {
		return this.httpClient.put<SSOConnection>(`${environment.trovataAPI('workspace')}/admin/identity/customer/sso/connection`, connection);
	}

	deleteSSOConnection(): Observable<void> {
		return this.httpClient.delete<void>(`${environment.trovataAPI('workspace')}/admin/identity/customer/sso/connection`);
	}
}
