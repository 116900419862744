import { environment } from '@trovata/environments/environment';
import { UserAccessSettings } from './feature.model';
import { SubscriptionNames } from './subscriptions-view.model';
import { UserGroup } from './user-group.model';

export interface Customer {
	customerId: string;
	email?: string;
	name?: string;
	phone?: string;
	timeZone?: string;
}

export interface CurrentCustomer {
	accountCount: number;
	customerId: string;
	enterprise: boolean;
	name: string;
	subscriptionType: SubscriptionNames;
	waitingToOnboard: boolean;
	freemiumConnectionUsed: boolean;
	settings: UserAccessSettings;
}

export interface GetCustomerUsersResponse {
	customerId: string;
	totalUsers: number;
	users: CustomerUser[];
}

export interface CurrentUser {
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	timeZone: string;
	creationDate: string;
	passwordLastUpdate: string;
	MFA: boolean;
	status: CustomerUserStatus;
	customerIds: string[];
}

export interface EditCurrentUserResponse {
	MFA: boolean;
	email: string;
	firstName: string;
	lastName: string;
	creationDate: string;
	customers: EditCurrentUserResponseCustomer[];
	lastActive: string;
	passwordLastUpdate: string;
	phone: string;
	timeZone: string;
	userId: string;
	status: CustomerUserStatus;
}

export interface CustomerUser {
	MFA: boolean;
	email: string;
	firstName: string;
	lastName: string;
	jobTitle: string;
	joinDate: string;
	lastActive: string;
	passwordLastUpdate: string;
	phone: string;
	timeZone: string;
	userGroupIds: number[];
	userId: string;
	company: string;

	status?: CustomerUserStatus; // TODO: remove optional once PATCH https://api.trovata.io/workspace/identity/customer/users/{userId} is fixed to include `status`
}

export interface CustomerUsersDict {
	[key: string]: CustomerUser;
}

export enum CustomerUserStatus {
	active = 'active',
	pending = 'pending',
	deleted = 'deleted',
}

export interface AddEditUserDialogData {
	type: AddEditUserDialogType;

	customerUserGroups?: UserGroup[];
	user?: CustomerUser;
	userIsAuthUser?: boolean;
}

export enum AddEditUserDialogType {
	add = 'add',
	edit = 'edit',
}

export interface EditCustomerBody {
	name?: string;
	email?: string;
	phone?: string;
	timeZone?: string;
}

export interface EditCurrentUserBody {
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	timeZone?: string;
}

export interface EditCurrentUserResponseCustomer {
	name: string;
	customerId: string;
}

export interface EditCustomerUserBody {
	jobTitle?: string;
	timeZone?: string;
	userGroupIds?: number[];
}

export interface CreateCustomerUserBody {
	email: string;
	firstName: string;
	jobTitle: string;
	lastName: string;
	phone: string;
	timeZone: string;
	userGroupIds: number[];
}

export interface LinkAccountsStatus {
	linkAccountsRequested: boolean;
	loggedInService: string;
}

export interface SSOConnection {
	ssoConnectionType: SSOConnectionType;
	ssoConnectionName?: string;
	signInEndpoint: string;
	signingCert: string;
	domainAliases: string[];
	allowSSOUserCreation: boolean;
	active: boolean;
}

export class SSOCompanyNameResponse {
	ssoConnectionName: string;
}

export class SSOCopyFields {
	ssoURL: string;
	audienceURI: string;
	relayState: string;
	nameIdFormat: string;
	nameIdAttr: string;
	emailAttr: string;
	constructor(ssoConnectionName: string) {
		this.ssoURL = `https://${environment.auth0Domain}/login/callback?connection=` + ssoConnectionName;
		this.audienceURI = 'urn:auth0:trovata-users:' + ssoConnectionName;
		this.relayState = 'Trovata-' + ssoConnectionName;
		this.nameIdFormat = 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress';
		this.nameIdAttr = 'email';
		this.emailAttr = 'email';
	}
}

export enum SSOConnectionType {
	MICROSOFT = 'MICROSOFT',
	SHIBBOLETH = 'SHIBBOLETH',
	OTHER = 'OTHER',
}

export enum SSOConnectionTypeDisplay {
	MICROSOFT = 'Azure/Entra',
	SHIBBOLETH = 'Shibboleth',
	OTHER = 'Other',
}
