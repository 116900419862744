import { SeriesZonesOptionsObject } from 'highcharts';
import { GenericOption } from './option.model';

export enum ChartType {
	column = 'column',
	pie = 'pie',
	line = 'line',
	scatter = 'scatter',
	stackedbar = 'stackedbar',
}

export const fixChartType: (oldChartType: string) => ChartType = (oldChartType: string) => {
	switch (oldChartType) {
		case 'show_chart':
			return ChartType.line;
		case 'scatter_plot':
			return ChartType.scatter;
		case 'pie_chart':
			return ChartType.pie;
		case 'stacked_bar_chart':
			return ChartType.stackedbar;
		case 'bar_chart':
			return ChartType.column;
		default:
			return ChartType[oldChartType];
	}
};

export const chartTypeOptions: GenericOption[] = [
	{
		key: ChartType.column,
		id: ChartType.column,
		displayValue: 'Bar Chart',
	},
	{
		key: ChartType.stackedbar,
		id: ChartType.stackedbar,
		displayValue: 'Stacked Bar Chart',
	},
	{
		key: ChartType.line,
		id: ChartType.line,
		displayValue: 'Line Chart',
	},
	{
		key: ChartType.scatter,
		id: ChartType.scatter,
		displayValue: 'Scatter Plot',
	},
	{
		key: ChartType.pie,
		id: ChartType.pie,
		displayValue: 'Pie Chart',
	},
];

export const getHighchartsType = (chartType: ChartType): 'area' | 'scatter' | 'pie' | 'column' => {
	switch (chartType) {
		case ChartType.line:
			return 'area';
		case ChartType.scatter:
			return 'scatter';
		case ChartType.pie:
			return 'pie';
		case ChartType.stackedbar:
			return 'column';
		case ChartType.column:
			return 'column';
	}
};

export class HighchartColors {
	generalChartColors: string[];
	forecastChartColors: string[];
	barColorScheme?: SeriesZonesOptionsObject[];
	grossColors: HighchartGrossColors;
	accountSummaryGradientStops: [number, string][];
	accountSummaryLineColor: string;
}

export class HighchartGrossColors {
	credit: string;
	debit: string;
	neutral: string;
	lightNeutral: string;
}

export interface GroupOrders {
	order: string[];
}
