import { Injectable } from '@angular/core';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { firstValueFrom, forkJoin, map, Observable, Subject, tap } from 'rxjs';
import { Tag } from '../models/tag.model';
import { GetTransactionPayload, Transaction } from '../models/transaction.model';
import { TransactionsService } from './transactions.service';
import { GLTag } from '../models/glTag.model';
import { GLCode } from '../models/gl-code.model';

@Injectable({
	providedIn: 'root',
})
export class TransactionsBatchService {
	requestSize: number;

	constructor(private transactionsService: TransactionsService) {
		this.requestSize = 1000;
	}

	getBatchTransactions(
		q?: string,
		positionType?: string,
		params?: SearchParameter[],
		fullAccountNumbers: boolean = false,
		tags: Tag[] = [],
		glTags: GLTag[] = [],
		glCodes: GLCode[] = [],
		tqlJSONExpression?: Object,
		loadingSubject$?: Subject<number>
	): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			loadingSubject$?.next(0);
			try {
				const totalTransactionsCount: number = await this.getTotalTransactionsCount(q, params, tqlJSONExpression);
				const totalRequests: number = Math.ceil(totalTransactionsCount / this.requestSize);

				const requestPayloads: GetTransactionPayload[] = [];
				for (let i = 0; i < totalRequests; i++) {
					const sizeParam: number = this.requestSize * i;
					const payload: GetTransactionPayload = {
						tags: tags,
						glTags: glTags,
						glCodes: glCodes,
						q,
						positionType,
						from: sizeParam,
						searchParams: params,
						size: this.requestSize,
						fullAccountNumbers,
						tqlJSONExpression,
					};
					requestPayloads.push(payload);
				}
				if (requestPayloads.length === 0) {
					resolve([]);
				} else {
					const batchResult: Transaction[] = await this.batchTransactionsHelper(requestPayloads, loadingSubject$);
					resolve(batchResult);
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	private batchTransactionsHelper(payloads: GetTransactionPayload[], loadingSubject$?: Subject<number>): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			let finishedRequests: number = 0;
			try {
				await forkJoin(
					payloads
						.filter((payload: GetTransactionPayload) => payload)
						.map((filteredPayload: GetTransactionPayload) =>
							this.getTransactions(filteredPayload).pipe(
								tap(() => {
									finishedRequests += 1;
									loadingSubject$.next((finishedRequests / payloads.length) * 100);
								})
							)
						)
				).subscribe(results => {
					const allTransactions: Transaction[] = Array.prototype.concat.apply([], results);
					resolve(allTransactions);
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	getTransactions(payload: GetTransactionPayload): Observable<Transaction[]> {
		return this.transactionsService
			.getTransactions({
				tags: payload.tags,
				glTags: payload.glTags,
				glCodes: payload.glCodes,
				q: payload.q,
				positionType: payload.positionType,
				from: payload.from,
				searchParams: payload.searchParams,
				size: payload.size,
				fullAccountNumbers: payload.fullAccountNumbers,
				tqlJSONExpression: payload.tqlJSONExpression,
			})
			.pipe(map(resp => resp.body.transactions));
	}

	getTotalTransactionsCount(q?: string, params?: SearchParameter[], tqlJSONExpression?: Object): Promise<number> {
		return new Promise(async (resolve, reject) => {
			try {
				const totalTransactions: number = (
					await firstValueFrom(this.transactionsService.getTransactionsSummary(q, null, null, params, null, null, null, tqlJSONExpression))
				).body.totalTransactions;
				resolve(totalTransactions);
			} catch (error) {
				reject(error);
			}
		});
	}
}
