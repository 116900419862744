import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import { ItemViewedEvent } from '../../models/recently-viewed';

export class InitHomeState {
	static readonly type: string = '[Home] InitHomeState';
}

export class ClearHomeState {
	static readonly type: string = '[Home] ClearHomeState';
}

export class ResetHomeState {
	static readonly type: string = '[Home] ResetHomeState';
}

export class GetHome {
	static readonly type: string = '[Home] GetHome';
	constructor(public shouldRefresh?: boolean) {}
}

export class GetBalancesReport {
	static readonly type: string = '[Home] GetBalancesReport';
	constructor(public accountIds?: string[]) {}
}

export class GetInflowsReport {
	static readonly type: string = '[Home] GetInflowsReport';
	constructor(public accountIds?: string[]) {}
}

export class GetOutflowsReport {
	static readonly type: string = '[Home] GetOutflowsReport';
	constructor(public accountIds?: string[]) {}
}

export class GetAccountSummary {
	static readonly type: string = '[Home] GetAccountSummary';
	constructor(
		public accountIds?: string[],
		public groupByKey?: GroupByKey
	) {}
}

export class SetAccountSummaryLoadingState {
	static readonly type: string = '[Home] SetAccountSummaryLoadingState';
	constructor(public isLoading: boolean) {}
}

export class SetTransactionsPreviewLoadingState {
	static readonly type: string = '[Home] SetTransactionsPreviewLoadingState';
	constructor(public isLoading: boolean) {}
}

export class GetTransactionsPreview {
	static readonly type: string = '[Home] GetTransactionsPreview';
	constructor(
		public accountIds?: string[],
		public startDate?: string,
		public endDate?: string
	) {}
}

export class GetHomePreferences {
	static readonly type: string = '[Home] GetHomePreferences';
}

export class UpdatePageViewPreferences {
	static readonly type: string = '[Home] SetPageViewPreferences';
	constructor(public updatedLastViewedItems: ItemViewedEvent[]) {}
}

export class UpdateHomeDataPreferencess {
	static readonly type: string = '[Home] UpdateHomeAccountFilterPreferences';
	constructor(
		public accountIds: string[],
		public groupBy: GroupByKey
	) {}
}
