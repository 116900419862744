import { Institution } from 'src/app/shared/models/institution.model';
import { Account } from './account.model';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { AnalysisTimeFrame } from '../../reports/models/analysis.model';
import { GLCode } from './gl-code.model';
import { Cadence } from 'src/app/shared/models/cadence.model';

export class Tag {
	public tagTitle: string;
	public tagDescription: string;
	public tagType: TagType;
	public tagMetadata: TagMetadata;
	public indexTime: string;
	public customerId: string;
	public tagId: string;
	public version?: TagVersion;
	public lastUpdatedTime: string;
	public priority?: number;
}

export const TagDependentObjectMap: object = {
	dependentTags: 'tags',
	dependentReports: 'reports',
	dependentForecasts: 'forecasts',
	dependentStreams: 'streams',
};

export class TagDependentItem {
	id: string;
	name: string;
}

export class TagDependentObjects {
	tagId: string;
	tagTitle: string;
	dependentForecasts?: TagDependentItem[];
	dependentStreams?: TagDependentItem[];
	dependentReports?: TagDependentItem[];
	dependentTags?: TagDependentItem[];
}

export enum TagVersion {
	v2 = 'v2',
	v3 = 'v3',
}

export class TagPayload {
	public tagTitle: string;
	public tagDescription: string;
	public q?: string;
	public params?: SearchParameter[];
	public tagType?: TagType;
	public includeTransactions?: string[];
	public excludeTransactions?: string[];
}

export enum TagsCSVToggleableColumns {
	tagDescription = 'tagDescription',
	tql = 'tql',
	parentTags = 'parentTags',
	transactionCounts = 'transactionCounts',
	lastUpdatedTime = 'lastUpdatedTime',
}

export const TagsCSVToggleableColDisplay: { label: string; value: TagsCSVToggleableColumns }[] = [
	{ label: 'Tag description', value: TagsCSVToggleableColumns.tagDescription },
	{ label: 'Transaction TQL search criteria', value: TagsCSVToggleableColumns.tql },
	{ label: 'Parent tags', value: TagsCSVToggleableColumns.parentTags },
	{ label: 'Number of transactions', value: TagsCSVToggleableColumns.transactionCounts },
	{ label: 'Last updated time', value: TagsCSVToggleableColumns.lastUpdatedTime },
];

export class TagCSVPayload {
	fields: TagsCSVToggleableColumns[];
}

export class TagMetadata {
	public displayInstitutions: Institution[];
	public institutionId?: string[];
	public displayAccounts: Account[];
	public accountId?: string[];
	public currency: string[];
	public tags: string[];
	public excludeTags: string[];
	public startDate: string;
	public endDate: string;
	public positionType: string[];
	public type: string[];
	public amountFilters: string[];
	public sortFields: any;
	public q: string;
	public isManual: boolean;
	public glCode1: string;
	public glCode2: string;
	public displayGlCodes;
	public includeTransactions: string[];
	public includeTransactionsCount: number;
	public excludeTransactions: string[];
	public excludeTransactionsCount: number;
	public tql?: any;
}

export class TagTreeNode {
	tag: Tag;
	childNodes?: TagTreeNode[];
	id: string;
	level: number;
	parent: Tag;
}

export class TagLineageAndFlatTree {
	lineageTree: TagTreeNode[];
	flatTree: string[];
	constructor(lineageTree: TagTreeNode[], flatTree: string[]) {
		this.lineageTree = lineageTree;
		this.flatTree = flatTree;
	}
}

export class TagChartPreferences {
	[tagId: string]: TagChartData;
}

export class TagChartData {
	periods: number;
	cadence: Cadence;
	timeFrame: AnalysisTimeFrame;
	chartType: string;
	netToggle: boolean;
}

export class TagSnackData {
	periods: number;
	timeFrame: AnalysisTimeFrame;
	chartType: string;
	netToggle: boolean;
	parameters: SearchParameter[];
	tag: Tag;
}

export enum AccountTagType {
	entity = 'Entity (Legacy)',
	region = 'Region (Legacy)',
	division = 'Division (Legacy)',
}

export enum TagType {
	all = 'all',
	transaction = 'transaction',
	glTag = 'gl_transaction',
	glTagAuto = 'gl_auto_transaction',
	entity = 'ENTITY',
	region = 'REGION',
	division = 'DIVISION',
}

export const acctTagTypeToLabel: (tagType: TagType) => AccountTagType = (tagType: TagType) => {
	switch (tagType) {
		case TagType.entity:
			return AccountTagType.entity;
		case TagType.region:
			return AccountTagType.region;
		case TagType.division:
			return AccountTagType.division;
	}
};

export interface EditTagBody {
	tagTitle: string;
	tagType: string;
	tagDescription: string;
	priority?: number;
	filters: {
		isManual?: boolean;
		includeTransactions?: string[];
		excludeTransactions?: string[];
		q?: string;
	};
}

export class PostTagResponse {
	tagId: string;
}

export class GetTagsResponse {
	tags: Tag[];
}

export class GetGlCodesResponse {
	glCodes: GLCode[];
}
export enum TagAction {
	include = 'include',
	exclude = 'exclude',
}

export enum TagOverlapTransactionMode {
	none = 'none',
	showAll = 'showAll',
	tagOverlapOnly = 'showOnlyOverlapped',
}
