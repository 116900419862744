import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { FilterOption } from './abstract-filter.model';
import { RoundingOption } from './rounding.model';
import { SortDirection, SortHeaderArrowPosition } from '@angular/material/sort';

@Injectable()
export class TablePaginator implements MatPaginatorIntl {
	changes: Subject<void> = new Subject<void>();

	// For internationalization, the `$localize` function from
	// the `@angular/localize` package can be used.
	firstPageLabel: string = 'First page';
	itemsPerPageLabel: string = 'Rows per page:';
	lastPageLabel: string = 'Last page';

	// You can set labels to an arbitrary string too, or dynamically compute
	// it through other third-party internationalization libraries.
	nextPageLabel: string = 'Next page';
	previousPageLabel: string = 'Previous page';

	getRangeLabel(page: number, pageSize: number, length: number): string {
		if (length === 0) {
			return 'No Results';
		}
		const minNum: number = page * pageSize + 1;
		let maxNum: number = (page + 1) * pageSize;
		if (maxNum > length) {
			maxNum = length;
		}
		return `${minNum}-${maxNum} of ${length}`;
	}
}

export enum RowExpansionType {
	forecastChildTable = 'forecastChildTable',
	transactionDetail = 'transactionDetail',
	transactionDetailVertical = 'transactionDetailVertical',
	auditLogDetail = 'auditLogDetail',
}

export enum ColumnType {
	icon = 'icon',
	string = 'string',
	currency = 'currency',
	stringSubText = 'stringSubText',
	customHTML = 'customHTML',
	matChips = 'matChips',
	menuButton = 'menuButton',
	slideToggle = 'slideToggle',
	button = 'button',
	multi = 'multi',
	expansionIcon = 'expansionIcon',
}

export enum StickyDirection {
	left = 'left',
	right = 'right',
}

export class PaginatedTableMatChip {
	chipPrefix?: string;
	chipPrefixClass?: string;
	text?: string;
	textClass?: string;
	icon?: string;
	iconClass?: string;
	class?: string;
	tooltip?: string;
}

export enum EditType {
	select = 'select',
	text = 'textEdit',
	currency = 'currencyEdit',
	button = 'buttonEdit',
}

export class PaginatedTableTemplateFields {
	// general display
	textAlign?: string;
	clickable?: boolean;
	clickableBinding?: string;
	tooltip?: string;
	tooltipBinding?: string;
	infoPanel?: string;
	sortingDataAccessor?: string;
	sortPlacement?: SortHeaderArrowPosition;
	displayBinding?: string;
	detailedHeader?: string;
	disabled?: boolean;
	columnClass?: string;
	cellClassBinding?: string;
	nullDisplay?: string;
	expansionTrigger?: boolean;

	// multi type
	columnTypeBinding?: string;

	// icon
	icon?: string;
	falseIcon?: string;
	iconColor?: string;
	iconClass?: string;
	falseIconClass?: string;
	iconMatBadge?: boolean;

	// currency
	currencyPath?: string;
	roundingNumeric?: number;
	roundingPath?: string;
}

export class PaginatedTableEditFields {
	type: EditType;
	disabled?: boolean;

	// text
	displayPath?: string;
	valuePath?: string;

	// currency
	currencyPath?: string;
	roundingPath?: string;
	rounding?: RoundingOption;

	// dropdown
	selectOpts?: FilterOption[];
	asyncOpts?: boolean;

	// button
	icon?: string;
	btnId?: string;
	aria?: string;
	btnLoading?: string;
}

export interface OptionalPaginatedColumnFields {
	header?: string;
	footer?: string;
	sortable?: boolean;
	included?: boolean;
	colGroup?: number;
	templateFields?: PaginatedTableTemplateFields;
	headerTemplateFields?: PaginatedTableTemplateFields;
	editFields?: PaginatedTableEditFields;
	stickyType?: StickyDirection;
	hideHeader?: boolean;
	hideHeaderGroupBorder?: boolean;
	altKey?: string;
	defaultToEnabled?: boolean;
}

export class PaginatedTableColumn {
	colDef: string;
	type: ColumnType;
	header: string;
	footer: string;
	sortable: boolean = false;
	included: boolean = true;
	colGroup: number;
	templateFields: PaginatedTableTemplateFields;
	headerTemplateFields: PaginatedTableTemplateFields;
	editFields: PaginatedTableEditFields;
	stickyType: StickyDirection;
	hideHeader: boolean;
	hideHeaderGroupBorder: boolean = false;
	altKey: string;
	defaultToEnabled: boolean;
	options?: OptionalPaginatedColumnFields;

	constructor(colDef: string, type: ColumnType, options?: OptionalPaginatedColumnFields) {
		this.options = options;
		this.colDef = colDef;
		this.type = type;
		this.header = options?.header;
		this.footer = options?.footer;
		this.sortable = options?.sortable ?? false;
		this.included = options?.included ?? true;
		this.defaultToEnabled = options?.defaultToEnabled ?? false;
		this.colGroup = options?.colGroup;
		this.templateFields = options?.templateFields || {};
		this.headerTemplateFields = options?.headerTemplateFields || {};
		this.editFields = options?.editFields;
		this.stickyType = options?.stickyType;
		this.hideHeader = options?.hideHeader;
		this.hideHeaderGroupBorder = options?.hideHeaderGroupBorder;
		this.altKey = options?.altKey;
	}
}

export class StringSubText {
	text: string;
	subText: string;

	constructor(text: string, subText: string) {
		this.text = text;
		this.subText = subText;
	}
}

export interface ExpandableRow {
	expanded?: boolean;
}

export class PaginatedTableViewModel<T> {
	dataSource: MatTableDataSource<T>;
	length: number;
	total: number;
	useCustomPagination: boolean;
	pageSize: number;

	constructor(
		public idPath: string,
		tableData: T[],
		public selectionMode: boolean = false,
		public defaultSortCol: string = idPath,
		public defaultSortDirection: SortDirection = 'asc',
		public emptyMessage?: string,
		public rowExpansionType?: RowExpansionType,
		public allowMultipleExpandedRows?: boolean,
		customLength?: number,
		public radioSelectionMode?: boolean,
		public hideHeaderRow?: boolean,
		public tagEditingEnabled: boolean = true,
		public tagsViewOnly: boolean = false
	) {
		this.length = 0;
		this.total = 0;
		this.length = customLength || tableData.length;
		if (defaultSortCol) {
			tableData = this.sortRows(tableData);
		}
		this.dataSource = new MatTableDataSource(tableData);
		this.dataSource.sortingDataAccessor = (data: T, sortHeaderId: string): number | string =>
			typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
	}

	sortRows(rows: T[]): T[] {
		return rows.sort((a, b) => {
			const aProp: number | string = typeof a[this.defaultSortCol] === 'string' ? a[this.defaultSortCol].toLowerCase() : a[this.defaultSortCol];
			const bProp: number | string = typeof b[this.defaultSortCol] === 'string' ? b[this.defaultSortCol].toLowerCase() : b[this.defaultSortCol];
			if (this.defaultSortDirection === 'desc') {
				return !aProp || aProp < bProp ? 1 : -1;
			} else if (this.defaultSortDirection === 'asc') {
				return !aProp || aProp <= bProp ? -1 : 1;
			} else {
				return 0;
			}
		});
	}

	toggleRow(row: ExpandableRow): void {
		if (!row.expanded && !this.allowMultipleExpandedRows) {
			this.collapseAllRows();
		}
		row.expanded = !row.expanded;
	}

	collapseAllRows(): void {
		if (this.rowExpansionType) {
			this.dataSource?.data?.forEach((row: T) => ((<ExpandableRow>row).expanded = false));
		}
	}
}
