import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { catchError, Observable, Subscription, tap, throwError } from 'rxjs';
import { SerializationService } from 'src/app/core/services/serialization.service';
import {
	Feature,
	FeatureId,
	featurePermissionMap,
	UserAccessConfig,
	AccessMap,
	ServiceFeatureMap,
	getServiceFeatureMap,
	PermissionId,
	FeatureReadPermissions,
	GetFeaturePermissionsResponse,
} from '../../models/feature.model';
import { FeatureConfigService } from '../../services/feature-config.service';
import { InitCustomerFeatureState, GetFeatureConfig, ClearCustomerFeatureState, GetFeaturePermissions } from '../actions/customer-feature.actions';
import { UserGroupService } from '../../services/user-group.service';
import { HeapAnalyticsService } from '@trovata/app/core/services/heap-analytics.service';

export class CustomerFeatureStateModel {
	userAccessConfig: UserAccessConfig;
	featurePermissions: Feature[];
	customerAccessMap: AccessMap;
	userAccessMap: AccessMap;
	serviceFeatureMap: ServiceFeatureMap;
}

@State<CustomerFeatureStateModel>({
	name: 'customerFeature',
	defaults: {
		userAccessConfig: null,
		featurePermissions: null,
		customerAccessMap: null,
		userAccessMap: null,
		serviceFeatureMap: null,
	},
})
@Injectable()
export class CustomerFeatureState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private configService: FeatureConfigService,
		private userGroupService: UserGroupService,
		private heapService: HeapAnalyticsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static userAccessMap(state: CustomerFeatureStateModel) {
		return state.userAccessMap;
	}

	@Selector()
	static customerAccessMap(state: CustomerFeatureStateModel) {
		return state.customerAccessMap;
	}

	@Selector()
	static serviceFeatureMap(state: CustomerFeatureStateModel) {
		return state.serviceFeatureMap;
	}

	static hasPermission(featureId: FeatureId) {
		return createSelector([CustomerFeatureState], (state: CustomerFeatureStateModel): boolean => {
			const readPermission: PermissionId = FeatureReadPermissions.get(featureId);
			// user should see a feature if they have permissions
			// if they are denied access, they won't have it in permissions and it will be in their customer sub
			return state.userAccessMap.permissionIds.has(readPermission);
		});
	}

	@Selector()
	static featureIds(state: CustomerFeatureStateModel) {
		return state.userAccessMap?.featureIds;
	}

	@Selector()
	static permissionIds(state: CustomerFeatureStateModel) {
		return state.userAccessMap?.permissionIds;
	}

	@Selector()
	static userAccessConfig(state: CustomerFeatureStateModel) {
		return state.userAccessConfig;
	}

	@Selector()
	static restrictedFeatureIds(state: CustomerFeatureStateModel) {
		return state.userAccessConfig.restrictedFeatureIds;
	}

	@Selector()
	static paymentsEnabled(state: CustomerFeatureStateModel) {
		return state.userAccessConfig.currentCustomer.settings.paymentsEnabled;
	}

	@Selector()
	static featurePermissions(state: CustomerFeatureStateModel) {
		return state.featurePermissions;
	}

	@Action(InitCustomerFeatureState)
	async InitCustomerFeatureState(context: StateContext<CustomerFeatureStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const featureConfigStateIsCached: boolean = this.featureConfigStateIsCached(deserializedState.customerFeature);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						const currentState: CustomerFeatureStateModel = context.getState();
						const alreadyInStore: boolean = this.featureConfigStateIsCached(currentState);
						if (featureConfigStateIsCached) {
							const state: CustomerFeatureStateModel = deserializedState.customerFeature;
							if (state.userAccessConfig) {
								// map has to be recreated because it can't be serialized https://www.ngxs.io/recipes/style-guide#avoid-saving-class-based-instances-in-your-state
								state.userAccessMap = featurePermissionMap<boolean>([...state.userAccessConfig.availableFeatures]);
								state.serviceFeatureMap = getServiceFeatureMap(state.userAccessConfig.availableServiceFeatures);
								state.customerAccessMap = featurePermissionMap<boolean>(state.featurePermissions);
							}
							context.patchState(state);
						} else if (!alreadyInStore) {
							context.dispatch(new GetFeatureConfig());
							context.dispatch(new GetFeaturePermissions());
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetFeatureConfig)
	getFeatureConfig(context: StateContext<CustomerFeatureStateModel>) {
		return this.configService.getFeatureConfig().pipe(
			tap((userAccessConfig: UserAccessConfig) => {
				const state: CustomerFeatureStateModel = context.getState();
				if (!userAccessConfig.availableFeatures) {
					// Since this is a common issue
					throw new Error('Missing availableFeatures array from GET /config');
				} else {
					state.userAccessConfig = userAccessConfig;
					state.userAccessMap = featurePermissionMap<boolean>([...state.userAccessConfig.availableFeatures]);
					state.serviceFeatureMap = getServiceFeatureMap(state.userAccessConfig.availableServiceFeatures);
					context.patchState(state);
				}
				this.heapService.addUserProperties({ subscriptionType: userAccessConfig.currentCustomer.subscriptionType });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(GetFeaturePermissions)
	getFeaturePermissions(context: StateContext<CustomerFeatureStateModel>): Observable<GetFeaturePermissionsResponse> {
		return this.userGroupService.getFeaturePermissions().pipe(
			tap((response: GetFeaturePermissionsResponse) => {
				const state: CustomerFeatureStateModel = context.getState();
				const featurePermissions: Feature[] = response.features;
				state.customerAccessMap = featurePermissionMap<boolean>(featurePermissions);
				state.featurePermissions = featurePermissions.sort((featureA: Feature, featureB: Feature) => {
					if (featureA.name.toLowerCase() < featureB.name.toLowerCase()) {
						return -1;
					} else if (featureA.name.toLowerCase() > featureB.name.toLowerCase()) {
						return 1;
					}
					return 0;
				});
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ClearCustomerFeatureState)
	clearCustomerFeatureState(context: StateContext<CustomerFeatureStateModel>) {
		this.appReadySub.unsubscribe();
		const state: CustomerFeatureStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private featureConfigStateIsCached(customerFeatureState: CustomerFeatureStateModel): boolean {
		if (customerFeatureState?.userAccessConfig && customerFeatureState?.featurePermissions) {
			return true;
		} else {
			return false;
		}
	}
}
